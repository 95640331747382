import './App.css';
import {
    Routes,
    Route, Link, NavLink
} from "react-router-dom";
import {useEffect, useState} from "react";


function App() {
    const projects = require("./projects.json");
    const bio      = require("./bio.json");
    const cv       = require("./cv.json");
    const [lang    , setLang    ] = useState(navigator.language.includes("pt") ? "pt" : "en");
    const [showMenu, setShowMenu] = useState(false);
    return <div className="App">
        <Header lang={lang} setLang={setLang} showMenu={showMenu} setShowMenu={setShowMenu}/>
        <Nav main={true} projects={projects} lang={lang} setLang={setLang} setShowMenu={setShowMenu}/>
        <main>
            {showMenu ? <Nav main={false} projects={projects} lang={lang} setLang={setLang} setShowMenu={setShowMenu}/> :
            <Routes>
                <Route path="/" element={<ProjectGrid projects={projects} lang={lang}/>}/>
                {projects.map(p => <Route key={p.url} path={`/projects/${p.url}`} element={<Project project={p} lang={lang}/>}/>)}
                <Route path="/about" element={<About bio={bio} cv={cv} lang={lang}/>}/>
            </Routes>}
        </main>
    </div>
}

function Header(props){
    return <header><Link to="/"><p className="h1" id="isabel">ISABEL MEDEIROS</p></Link> <span id="menu-button" onClick={() => props.setShowMenu(!props.showMenu)}>{props.showMenu ? "✕" : "⋮"}</span>
        {/*<div id="lang"><LanguageToggle lang={props.lang} setLang={props.setLang} setShowMenu={props.setShowMenu}/></div>*/}</header>
}

function Nav(props){
    return <nav className={props.main ? "main-menu" : ""}>
        <ul id="sidebar-menu">
            <li className="link">
                <NavLink to="/"
                         className={({isActive}) => isActive ? "current-project-link-a" : ""}
                         onClick={() => props.setShowMenu(false)}>
                    {props.lang === "pt" ? "Projetos" : "Projects"}
                </NavLink>
                <ul>
                    {props.projects.map( (p,k) => <li className="project-link-li" key={k}>
                        <span className="project-link-year">{p.year}</span> &nbsp;
                        <NavLink className={({isActive}) => "project-link-title " + (isActive ? "current-project-link-a" : "")}
                                 onClick={() => props.setShowMenu(false)}
                                 to={"/projects/"+p.url}
                        >
                            {p.title}
                        </NavLink>
                    </li>)}
                </ul>
            </li>
            <li className="link" style={{marginTop: ".5em"}}>
                <NavLink className={({isActive}) => isActive ? "current-project-link-a" : ""} to="/about" onClick={() => props.setShowMenu(false)}>
                    {props.lang === "pt" ? "Sobre" : "About"}
                </NavLink>
            </li>
            <li style={{marginTop: ".5em"}}>
                <LanguageToggle lang={props.lang} setLang={props.setLang} setShowMenu={props.setShowMenu}/>
            </li>
        </ul>
    </nav>
}

function LanguageToggle(props) {
    const selectedStyle = {textDecoration: "underline"};
    return <div id="language-toggle">
        <button style={props.lang === "en" ? selectedStyle : null}
                onClick={() => {props.setLang("en"); props.setShowMenu(false)}}
        >
            EN
        </button>/
        <button style={props.lang === "pt" ? selectedStyle : null}
                onClick={() => {props.setLang("pt"); props.setShowMenu(false)}}
        >
            PT
        </button>
    </div>
}

function ProjectGrid(props) {
/*
/*<div className="thumbnail_container">
        {props.projects.map(p => (<a href={"/projects/"+p.url}>
            <div className="thumbnail">
                <div className="thumbnail-img"
                    id={p.url+"-thumbnail-img"}
                    style={{backgroundImage: `url(${p.cover_image})`}}/>
                <div className="thumbnail-text">
                    {p.title} ({p.year})
                </div>
            </div>
        </a>))}
    </div>*/
    useEffect(() => {
        document.title = "Isabel Medeiros";
    }, []);
    return (
    <ul className="image-gallery">
        {props.projects.map((p,k) => <li key={k}>
            <Link to={"/projects/"+p.url}>
                <div className="thumbnail-wrapper">
                    <img src={p.cover_image} alt={p.title}/>
                </div>
                <div className="overlay">
                    <span className="overlay-text">
                        {p.title} ({p.year})
                    </span>
                </div>
                {/*<p className="grid-mobile-title">{p.title} ({p.year})</p>*/}
            </Link>
        </li>)}
    </ul>)
}

function Project(props) {
    useEffect(() => {
        document.title = "Isabel Medeiros - "+props.project.title;
        window.scrollTo(0,0);
    });
    return <div id="project-container">
            <div id="siema-gallery">
                <div className="siema">
                    {props.project.images.map( (i,n) => <img src={i} key={n} alt={props.project.title + ": imagem "+n}/>)}
                </div>
            </div>
            <div id="project-text">
                <p className="h1" id="project-title">
                    <span dangerouslySetInnerHTML={{__html: props.project.title}}/>
                </p>
                <p id="project-description">
                    <span dangerouslySetInnerHTML={{__html: props.project["media_"+props.lang]}}/>
                    <br/>
                    <span dangerouslySetInnerHTML={{__html: props.project.year}}/>
                </p>
                <span dangerouslySetInnerHTML={{__html: props.project["description_"+props.lang]}}/>
                <br/>
                <p>
                    <span dangerouslySetInnerHTML={{__html: props.project["location_"+props.lang]}}/>
                </p>
                <br/>
                <br/>
                <Link className="back" to="/">⤶ back</Link>
                <br/>
                <br/>
                <br/>
            </div>
        </div>
}

function About(props) {
    useEffect(() => {
        document.title = "Isabel Medeiros - "+(props.lang === "pt" ? "Sobre" : "About");
    }, [props.lang]);
    useEffect(() => {
        window.scrollTo(0,0)
    },[])
    return <div>
        <div id="bio-container">
            <div id="bio-text" dangerouslySetInnerHTML={{__html: props.bio["text_"+props.lang]}}/>
        </div>
        <div id="cv-container">
            <p className="cv-heading">CV</p>
            <br/>
            {props.cv.map((cvGroup,k) => <div className="cv-container" id="<%-cvGroup.tag%>-container" key={k}>
                <p className="table-title"
                   id={cvGroup.tag+"-table-title"}
                   dangerouslySetInnerHTML={{__html: cvGroup["title_"+props.lang]}}/>
                <table className="cv-table" id={cvGroup.tag+"-table"}>
                    <tbody>
                    {cvGroup.items.map( (item,k) => <tr className="cv-entry" key={k}>
                        <td className="cv-date">
                            {item.date}
                        </td>
                        <td className="cv-description" dangerouslySetInnerHTML={{__html: item["description_"+props.lang]}}/>
                     </tr>)}
                    </tbody>
            </table><br/><br/>
            </div>)}
            <Link className="back" to="/">⤶ back</Link>
            <br/>
            <br/>
            <br/>
            </div>
            </div>;
}

export default App;
